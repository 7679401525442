<template>
  <v-row class="px-4 pb-4">
    <v-col cols="12" class="pt-1 pb-0 d-flex justify-space-between">
      <div class="d-flex">
        <v-icon small left color="primary">mdi-file-chart-outline</v-icon>
        <h4 class="font-weight-bold primary--text">Promedio de Visitas</h4>
      </div>
      <v-expand-transition>
        <h4 class="font-weight-bold black--text" v-if="!loading">TOTAL: {{dataVisita.TOTAL_VISITA}}</h4>
      </v-expand-transition>
    </v-col>
    <v-col cols="12" class="py-0 d-flex align-center" :class="{'mt-n4': !loading}">
      <template v-if="loading">
        <v-skeleton-loader
          class="d-flex justify-center align-center"
          width="10%"
          height="100"
          type="button"
        ></v-skeleton-loader>
        <v-skeleton-loader
          class=""
          width="100%"
          type="list-item-two-line"
        ></v-skeleton-loader>
      </template>
        <template v-if="!loading">
          <div class="d-flex flex-column align-center justifi-center flex-grow-0 pb-4">
            <span class="text-h1 font-weight-bold primary--text pb-0" v-text="dataVisita.PROMEDIO" />
            <span class="caption blue-grey--text mt-n2">Visitas</span>
          </div>
          <div class="flex-grow-1 ml-n2">
            <apexchart
              type="bar"
              height="150"
              :options="chartOptions"
              :series="barData"
            />
          </div>
        </template>
    </v-col>
    <v-col cols="12" class="pt-0"><v-divider class="elevation-1"/></v-col>
  </v-row>
</template>
<script>

const dataDefault = () => ({
  PROMEDIO: 0,
  VISITAS: 0,
  VISITAS_NO_PLANIFICADAS: 0,
  TOTAL_VISITA: 0,
})

export default {
  name: 'PromedioVisita',
  props: {
    datos: {
      type: Object,
      default: dataDefault()
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  data(){
    return {
      dataVisita: this.datos,
      chartOptions: {
        legend: {
          show: false,
        },
        grid: {
          borderColor: '#ffffff',
        },
        chart: {
          toolbar: {
            show: false
          },
          type: 'bar',
          height: 430
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              enabled: false,
              position: 'top',
            },
          }
        },
        colors: ['#009688', '#FB8C00'],
         fill: {
            opacity: 1
          },
          stroke: {
            width: 1,
            colors: ['#000']
          },
        dataLabels: {
          enabled: true,
          offsetX: -60,
          style: {
            fontSize: '12px',
            colors: ['#fff']
          },
          formatter: function (val, opt) {
            return opt.w.globals.seriesNames[opt.seriesIndex] + ":  " + val
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#fff']
        },
        tooltip: {
          shared: true,
          intersect: false
        },
        xaxis: {
          categories: ['Promedios'],
          labels: {
            show: true
          },
          lines: {
            show: false
          }
        },
        yaxis: {
          labels: {
            show: false
          }
        },
      },
    }
  },
  watch:{
    loading(val){
      if(val){
        this.dataVisita = dataDefault()
      } else {
        this.dataVisita = {...this.datos}
      }
    }
  },
  computed: {
    barData(){
      return [
        {
          name: 'Planidficadas',
          data: [this.datos.VISITAS]
        }, {
          name: 'No Planificadas',
          data: [this.datos.VISITAS_NO_PLANIFICADAS]
        }
      ]
    }
  }
}
</script>
